import BR from 'primitives/Layout/BR';
import FilledButton from 'primitives/Buttons/FilledButton';
import getPowerAmpUrl from 'utils/hacks/getPowerAmpUrl';
import H3 from 'primitives/Typography/Headings/H3';
import H6 from 'primitives/Typography/Headings/H6';
import Input from 'primitives/Forms/Input';
import Label from 'primitives/Forms/Label';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PageWrapper from 'primitives/Layout/PageWrapper';
import Section from './primitives/Section';
import {
  deleteSubscription,
  updateSubscription,
} from 'shared/api/subscription';
import { useState } from 'react';

const EXPIRATION_OFFSET = 3 * 24 * 3600 * 1000; // Three days in milliseconds.

type Props = {
  expiration: number;
  isTrial: boolean;
  profileId: number | string;
  sessionId: string;
  subscriptionSource: string;
  subscriptionType: string;
};

const internalApi = 'https://ampinternal.ihrprod.net';
const qaApi = 'https://us-qa.api.iheart.com';
const stgApi = 'https://us-stg.api.iheart.com';

function TesterOptions({
  isTrial,
  profileId,
  sessionId,
  subscriptionSource,
  subscriptionType,
}: Props) {
  const [endpointBase, setEndpointBase] = useState(
    getPowerAmpUrl().indexOf('-qa') > 0 ? qaApi : stgApi,
  );
  const [expirationDate, setExpirationDate] = useState(
    new Date().getTime() + EXPIRATION_OFFSET,
  );
  const [isUserTrial, setIsUserTrial] = useState(isTrial);
  const [userProfileId, setUserProfileId] = useState(profileId);
  const [currentUserSubSource, setCurrentUserSubSource] = useState(
    subscriptionSource || 'RECURLY',
  );
  const [currentUserSubType, setCurrentUserSubType] =
    useState(subscriptionType);

  const handleUpdateInternalSubscription = () => {
    updateSubscription(
      userProfileId,
      sessionId,
      currentUserSubType,
      currentUserSubSource,
      expirationDate,
      isUserTrial,
      endpointBase,
    )
      .catch(error => {
        alert(
          'There was an issue: \n\n Make sure you are using the correct endpoint base \n Make sure you are connected to secure VPN',
        );
        return error;
      })
      .then(() => window.location.reload());
  };

  const handleDeleteInternalSubscription = () => {
    deleteSubscription(userProfileId, sessionId, endpointBase).then(() =>
      window.location.reload(),
    );
  };

  return (
    <PageWrapper marginValue="9rem">
      <H6>
        Current User is:{' '}
        <span style={{ textDecoration: 'underline' }}>{subscriptionType}</span>,
        type:{' '}
        <span style={{ textDecoration: 'underline' }}>
          {subscriptionSource}
        </span>
        , base URL:{' '}
        <span style={{ textDecoration: 'underline' }}>
          {getPowerAmpUrl().indexOf('-qa') > 0 ? qaApi : internalApi}
        </span>
      </H6>
      <BR />
      <H3>Subscription Settings: </H3>
      <ul>
        <li style={{ color: 'red' }}>
          Must be connected to Cisco iHeart VPN for this form to work.
        </li>
        <li>
          Make sure the endpointBase corresponds with your test environment
          (prod / qa).
        </li>
        <li>
          Page will reload if subscription is successfully updated or deleted.
        </li>
      </ul>
      <Label>Endpoint Base</Label>
      <BR />
      <select
        onChange={e => setEndpointBase(e.target.value)}
        value={endpointBase}
      >
        <option value={internalApi}>{`PROD - ${internalApi}`}</option>
        <option value={qaApi}>{`QA - ${qaApi}`}</option>
        <option value={stgApi}>{`STG - ${stgApi}`}</option>
      </select>
      <BR />
      <BR />
      <Label>Profile Id</Label>
      <Input
        onChange={e => setUserProfileId(e.target.value)}
        type="number"
        value={userProfileId}
      />
      <BR />
      <Label>Expiration Date default 72 hours from now</Label>
      <Input
        onChange={e =>
          setExpirationDate(new Date(String(e.target.value)).getTime())
        }
        type="date"
      />
      <BR />
      <Label>is Trial ({String(isUserTrial)})</Label>
      <input
        checked={isUserTrial}
        onChange={() => setIsUserTrial(!isUserTrial)}
        type="checkbox"
      />
      <Label>User SubSource</Label>
      <BR />
      <select
        onChange={e => setCurrentUserSubSource(e.target.value)}
        value={currentUserSubSource}
      >
        <option value="RECURLY">RECURLY</option>
        <option value="APPLE">APPLE</option>
        <option value="GOOGLE">GOOGLE</option>
        <option value="AMAZON">AMAZON</option>
        <option value="ROKU">ROKU</option>
      </select>
      <BR />
      <Label>user SubType</Label>
      <BR />
      <select
        onChange={e => setCurrentUserSubType(e.target.value)}
        value={currentUserSubType}
      >
        <option value="NONE">NONE</option>
        <option value="FREE">FREE</option>
        <option value="PLUS">PLUS</option>
        <option value="PREMIUM">PREMIUM</option>
      </select>
      <BR />
      <BR />
      <FilledButton onClick={() => handleUpdateInternalSubscription()}>
        Submit
      </FilledButton>
      <BR />
      <BR />
      <Section deleteZone>
        <Label>
          Delete user&apos;s subscription data from database. Use this if you
          want to make the user eligible for a trial.
        </Label>
        <BR />
        <BR />
        <OutlinedButton onClick={() => handleDeleteInternalSubscription()}>
          Delete Subscription Data
        </OutlinedButton>
      </Section>
    </PageWrapper>
  );
}

export default TesterOptions;
