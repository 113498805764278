import styled from '@emotion/styled';

type Props = {
  deleteZone?: boolean;
};

const Section = styled('div')<Props>(({ deleteZone = false, theme }) => ({
  border: `0.1rem solid ${
    deleteZone ? theme.colors.red.primary : theme.colors.gray.secondary
  }`,
  marginBottom: '1rem',
  padding: '2rem',
}));

export default Section;
